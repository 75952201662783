// Scripts for the support login modal component
import '../../../../styles/src/components/support/login-modal.scss';

// Login modal toggles
let loginModal = document.getElementById('milo-login-modal');
let modalOpen = document.getElementById('login-modal-open');
let modalClose = document.getElementById('login-modal-close');

if( modalOpen ) {
  modalOpen.addEventListener('click', function() {
    loginModal.showModal();
  });
}

if( modalClose ) {
  modalClose.addEventListener('click', function() {
    loginModal.close();
  });
}
