// Scripts for the file list component

// Applies preload changes to file lists
jQuery('.file-list--preload').addClass('file-list--is-collapsed').slideToggle(0);
jQuery('.file-list').removeClass('file-list--preload');

// Toggles file folder icons and file lists on click
jQuery('.file-list__folder').click(function() {
  jQuery(this).find('.file-list__icon').toggleClass('file-list__icon--hidden');
  jQuery(this).siblings('.file-list').toggleClass('file-list--is-clicked');
  jQuery(this).siblings('.file-list').toggleClass('file-list--is-collapsed');
  jQuery(this).siblings('.file-list').slideToggle();
});

// Controls hover behavior for individual files
jQuery('.browser-item').hover(
  function() {
    jQuery(this).children('.browser-item__icon').addClass('browser-item__icon--is-hovered');
    jQuery(this).children('.browser-item__text').addClass('browser-item__text--is-hovered');
    jQuery(this).children('.browser-item__text').children('.browser-item__text--title').addClass('browser-item__text--is-hovered');
    jQuery(this).siblings('.browser-item__button').children('.browser-item__link').addClass('browser-item__link--is-hovered');
    jQuery(this).siblings('.browser-item__button').children('.browser-item__link').children('.browser-item__download').addClass('browser-item__download--is-hovered');
  },
  function() {
    jQuery(this).children('.browser-item__icon').removeClass('browser-item__icon--is-hovered');
    jQuery(this).children('.browser-item__text').removeClass('browser-item__text--is-hovered');
    jQuery(this).children('.browser-item__text').children('.browser-item__text--title').removeClass('browser-item__text--is-hovered');
    jQuery(this).siblings('.browser-item__button').children('.browser-item__link').removeClass('browser-item__link--is-hovered');
    jQuery(this).siblings('.browser-item__button').children('.browser-item__link').children('.browser-item__download').removeClass('browser-item__download--is-hovered');
  }
);
jQuery('.browser-item__button').hover(
  function() {
    jQuery(this).siblings('.browser-item__icon').addClass('browser-item__icon--is-hovered');
    jQuery(this).siblings('.browser-item__text').addClass('browser-item__text--is-hovered');
    jQuery(this).siblings('.browser-item__text').children('.browser-item__text--title').addClass('browser-item__text--is-hovered');
    jQuery(this).children('.browser-item__link').addClass('browser-item__link--is-hovered');
    jQuery(this).children('.browser-item__link').children('.browser-item__download').addClass('browser-item__download--is-hovered');
  },
  function() {
    jQuery(this).siblings('.browser-item__icon').removeClass('browser-item__icon--is-hovered');
    jQuery(this).siblings('.browser-item__text').removeClass('browser-item__text--is-hovered');
    jQuery(this).siblings('.browser-item__text').children('.browser-item__text--title').removeClass('browser-item__text--is-hovered');
    jQuery(this).children('.browser-item__link').removeClass('browser-item__link--is-hovered');
    jQuery(this).children('.browser-item__link').children('.browser-item__download').removeClass('browser-item__download--is-hovered');
  }
);
