// Scripts for the support login form component
import '../../../../styles/src/components/support/login.scss';

// Controls the ability to submit the password form
const loginCheck = document.getElementById('browser-login-check');
const loginSubmit = document.getElementById('browser-login-submit');

if( loginCheck ) {
  loginCheck.addEventListener('click', function() {
    if( loginSubmit.classList.contains('browser-login__button--inactive') ) {
      loginSubmit.classList.remove("browser-login__button--inactive");
    } else {
      loginSubmit.classList.add("browser-login__button--inactive");
    }
  });
}
