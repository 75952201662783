// Scripts for the support browser page template
'use strict'

/**
 * Imports SCSS for Webpack parsing and sets strict mode
 */
import '../../../styles/src/pages/support-browser.scss';

/**
 * Component imports
 */
// Support login modal
import '../components/support/login-modal';
// Support login
import '../components/support/login';
// Support directory
import '../components/support/directory';
// File browser
import '../components/support/file-browser';
// File list
import '../components/support/file-list';
// Browser items
import '../components/support/browser-item';
// Support widgets
import '../components/support/widgets';

/**
 * Page-specific scripting
 */
